.form-input,
.form-textarea,
.form-select,
.form-multiselect,
.form-radio,
.form-checkbox,
.form-switch,
.form-checkbox::before,
.form-radio::before,
.form-switch::before {
  @apply transition-all duration-200 ease-in-out;
}

.form-input,
.form-textarea,
.form-select,
.form-multiselect {
  @apply appearance-none tracking-wide outline-none placeholder:font-light focus:outline-none;
  contain: paint;
}

.form-select {
  @apply bg-center bg-no-repeat;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewbox='0 0 20 20'%3e%3cpath stroke='%235C6B8A' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
  print-color-adjust: exact;
}

.form-multiselect {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  print-color-adjust: unset;
}

.form-checkbox,
.form-radio,
.form-switch {
  @apply relative shrink-0 cursor-pointer select-none appearance-none overflow-hidden outline-none
   focus:outline-none;
  print-color-adjust: exact;
}

.form-switch {
  @apply [--thumb-border:2px] before:absolute focus-visible:ring
  before:top-[var(--thumb-border)] before:left-[var(--thumb-border)]
  before:h-[calc(100%-var(--thumb-border)*2)]
  before:w-[calc((100%/2)-(var(--thumb-border)*2))]
  checked:before:translate-x-[calc(100%+(var(--thumb-border)*2))];
}

.form-switch.is-outline {
  @apply before:w-[calc((100%/2)-(var(--thumb-border)*2)-1px)]
  checked:before:translate-x-[calc(100%+(var(--thumb-border)*2)+2px)];
}

.form-radio {
  --tw-thumb: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3.5'/%3e%3c/svg%3e");
  @apply before:ease-out;
}

.form-checkbox {
  --tw-thumb: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  @apply before:origin-bottom before:ease-in-out;
}

.form-radio,
.form-checkbox {
  @apply inline-block border ease-out before:absolute before:inset-0 before:h-full
  before:w-full before:scale-0 checked:before:scale-100 hover:shadow-lg;
}

.form-radio.is-basic,
.form-checkbox.is-basic {
  @apply bg-origin-border before:bg-center before:bg-no-repeat before:[background-size:100%_100%] before:[background-image:var(--tw-thumb)];
}

.form-radio.is-outline,
.form-checkbox.is-outline {
  @apply before:[mask-image:var(--tw-thumb)];
}

.form-range {
  @apply block w-full appearance-none bg-transparent outline-none
  [--range-thumb-size:1.125rem] [--range-track-h:0.375rem];
}

.form-range::-webkit-slider-thumb {
  @apply relative top-[50%] h-[var(--range-thumb-size)] w-[var(--range-thumb-size)] cursor-pointer appearance-none rounded-full border-none bg-current active:opacity-[.85];
  transform: translateY(-50%);
}

.form-range::-moz-range-thumb {
  @apply relative top-[50%] h-[var(--range-thumb-size)] w-[var(--range-thumb-size)] cursor-pointer appearance-none rounded-full border-none bg-current active:opacity-[.85];
}

.form-range::-moz-range-track {
  @apply h-[var(--range-track-h)] w-full rounded-full bg-slate-150 dark:bg-navy-500;
}

.form-range::-webkit-slider-runnable-track {
  @apply h-[var(--range-track-h)] w-full rounded-full bg-slate-150 dark:bg-navy-500;
}
