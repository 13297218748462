@tailwind base;
@tailwind components;
@tailwind utilities;

$fa-font-path: "/assets/webfonts";
@import "node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-pro/scss/regular";
@import "node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "node_modules/@fortawesome/fontawesome-pro/scss/light";

@import 'ngx-toastr/toastr';

@import "./assets/scss/base.css";
@import "./assets/scss/components.css";
@import "./assets/scss/pages.css";

* {
  box-sizing: border-box;
}
